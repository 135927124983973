  
  /* Group 1000000886 */
  .group1
  {
  position: relative;
  width: 596px;
  height: 396.59px;
  
  top: 7.7px;
  opacity: 0.7;
  
  }
  
  /* Ellipse 2995 */
  .group2
  {
  position: absolute;
  width: 300.77px;
  height: 100.77px;
  
  top: 7.7px;
  
  background: #8929D5;
  mix-blend-mode: multiply;
  filter: blur(126.5px);
  }
  
  /* Ellipse 2996 */
  
  .group3
  {
  position: absolute;
  width: 257.01px;
  height: 100.01px;
  
  top: 7.29px;
  
  background: #D927DD;
  mix-blend-mode: multiply;
  filter: blur(143.45px);
  }
  
  /* Ellipse 2997 */
  .group4
  {
  position: absolute;
  width: 199.41px;
  height: 100.41px;
  
  top: 7.09px;
  
  background: #6020E7;
  mix-blend-mode: multiply;
  filter: blur(106.65px);
  }
  
  
  