  

  .buyer-toggle {
    padding: 8px 1px;

    display: inline-block;
    
    border:1px solid #0ECB81;


  }
  





  .buyer-toggle input[type="radio"]:checked+label  {

    background-color: #0ECB81;
   
    color: white;
    
  }


  .sell-toggle input[type="radio"]:checked+label   {

    background-color: red;
   
    color: white;
    
  }

  

  .sell-toggle {
    padding: 8px 1px;

    display: inline-block;
    
    border:1px solid red;


  }
  





  .sell-toggle input[type="radio"]:checked+label  {

    background-color: red;
   
    color: white;
    
  }


  .sell-toggle input[type="radio"]:checked+label   {

    background-color: red;
   
    color: white;
    
  }

