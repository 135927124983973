.btn-close {
    height: 20px;
    
    width: 20px;  
    font-size: 1em;
    font-weight: normal;

    border-radius: 50%;
    background-color:white ;
    border:1px solid black;
    color: black;
    text-align: center;
    cursor: pointer;
  }

  body {
    margin: 0;
    padding: 0;
  }
  .close-icon
  {
    display:block;
    box-sizing:border-box;
    width:20px;
    height:20px;
    border-width:3px;
    border-style: solid;

    border-color:#B9B8BC;
    border-radius:100%;
    background: -webkit-linear-gradient(-45deg, transparent 46%, white 46%,  white 56%,transparent 56%,transparent 100%), -webkit-linear-gradient(45deg, transparent 0%, transparent 46%, white 46%,  white 56%,transparent 56%, transparent 100%);
    background-color:#B9B8BC;
   position: relative;
    transition: all 0.3s ease;
  }
