@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  
  -webkit-text-fill-color: #171717;
  -webkit-box-shadow: 0 0 0px 1000px #F5F5F5 inset;
  box-shadow: 0 0 0px 1000px #F5F5F5 inset;
  transition: background-color 5000s ease-in-out 0s;

  color: white;
}
@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}




.accordion {
  
  max-width: 400px;
}

.accordion-item {
  
  margin-bottom: 1px;

}

.accordion-toggle {
  
  display: block;
  width: 100%;

  text-align: left;
  cursor: pointer;
}

.accordion-content {
  
  padding: 10px;
  display: none;
}

/* Show the content when the button is clicked */
.accordion-toggle:focus + .accordion-content {
  display: block;
}